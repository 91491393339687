
<template>
	<div class="login-container">
		<img src="@/assets/images/logo-top.png" alt="" class="login-top">
		<div class="login-form">
			<div class="lg-tab">
				<div class="item" v-if="active==1" :class="msgActive?'active':''">找回密码</div>
				<div class="item" v-if="active==2" :class="msgActive?'active':''">重置密码</div>
			</div>
			<el-form ref="form" :rules="rules" :model="form">
				<div v-if="active==1">
					<!-- 第一步验证手机号 -->
					<div class="login-item">
						<el-input v-model="form.mobile" placeholder="请输入手机号"></el-input>
					</div>
					<div class="login-item">
						<slider-check></slider-check>
					</div>
					<div class="login-item">
						<el-input v-model="form.vercode" placeholder="输入验证码" class="vercode"></el-input>
						<el-button type="primary" class="vercode-btn" :disabled="!checked" @click="sendCode">发送验证码</el-button>
					</div>
				</div>
				<div v-if="active==2">
					<!-- 第二步重置密码 -->
					<div class="login-item">
						<el-input v-model="form.password" type="password" placeholder="请输入新密码"></el-input>
					</div>
					<div class="login-item">
						<el-input v-model="form.newPassword" type="password" placeholder="请再次输入新密码"></el-input>
					</div>	
				</div>
			</el-form>
			<el-button type="primary" class="login-btn" @click="next" v-if="active<2">下一步</el-button>
			<el-button type="primary" class="login-btn" v-preventReClick @click="onSubmit" v-else>确定</el-button>
			<div class="opear">
				<div class="opear-item"></div>
				<div class="opear-item">
					已有帐号？ <span class="opear-btn" @click="onLogin">马上登录</span>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {phoneValid} from '@/utils'
	import {setToken,getWxToken} from '@/utils/auth'
	import SliderCheck from "@/views/pc/login/components/SliderCheck";
	export default {
		components: {
			"slider-check": SliderCheck,
		},
		data() {
			return {
				checked: true,
				msgActive: true,
				form: {
					mobile: '',
					vercode: '',
					password: '',
					newPassword: '',
				},
				originForm: {},
				active: 1,
				rules: {
					mobile: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}],
					vercode: [{
						required: true,
						message: '输入验证码',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入新密码',
						trigger: 'blur'
					}],
					newPassword: [{
						required: true,
						message: '请再次输入新密码',
						trigger: 'blur'
					}],
				}
			};
		},
		watch: {
			form: {
				handler(newVal, oldVal) {
					let flag = JSON.stringify(this.form) == JSON.stringify(this.originForm)
					if (!flag) {
						if (!!this.form.mobile && !!this.form.password) {
							this.checked = true;
						} else {
							this.checked = false;
						}
					}
				},
				immediate: false,
				deep: true, //监听对象
			}
		},
		computed: {
			redirect() {
				return this.$route.query.redirect
			}
		},
		methods: {
			//发送验证码
			sendCode() {console.log(1)},
			onLogin() {
				this.$router.push({ name: 'Login'})
			},
			onSubmit() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						// this.$toast('通过')
						this.$http
							.post('', this.form)
							.then(res => {
								// console.log(res)
							})
							.catch(err => {
								// document.querySelector('body').innerHTML = err
								// document.forms[0].submit()
							})
					} else {
						// this.$toast('请填写完整表单')
						return false;
					}
				});
			},
			next() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						if (this.active++ > 1) this.active = 0;
					} else {
						// this.$toast('请填写完整表单')
						return false;
					}
				});
			},
		},
	};
</script>

<style lang='less' scoped>
	.login-container {
		width: 100%;
		height: 100vh;
		padding-top: 70px;
		background-image: url("~@/assets/images/login-bg.jpg");
		background-size: cover;
		.login-top {
			width: 306px;
		}
		.login-form {
			width: 400px;
			margin: 40px auto 0;
			padding: 10px 30px 30px;
			border-radius: 6px;
			background: #fff;
			.lg-tab {
				display: flex;
				border-bottom: 1px solid #f5f5f5;
				margin-bottom: 30px;
				.item {
					margin-right: 30px;
					line-height: 70px;
					cursor: pointer;
					&.active {
						border-bottom: 2px solid #333;
					}
				}
			}
			.login-item {
				margin-bottom: 30px;
				/deep/.el-input__inner {
					background: #F4F4F4;
					border: 1px solid #DADADA;
					border-radius: 4px;
					font-size: 16px;
				}
				.vercode {
					width: calc(100% - 140px);
				}
				.vercode-btn {
					width: 120px;
					margin-left: 20px;
					background: #dadada;
					border: 1px solid #dadada;
					color: #333;
					border-radius: 4px;
				}
			}
			.login-btn {
				width: 340px;
				height: 44px;
				margin-top: 10px;
				margin-bottom: 15px;
				background: #D71A18;
				border-radius: 4px;
				&.is-disabled {
					background: #DADADA;
					border: 1px solid #DADADA;
				}
			}
			.opear {
				display: flex;
				justify-content: space-between;
				color: #999;
				font-size: 12px;
				.opear-btn {
					color: #D71A18;
					cursor: pointer;
				}
			}
		}
	}
</style>
